

export const MenuItems = [
    {
        title: 'home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'resume',
        url: '/resume',
        cName: 'nav-links'
    },
    {
        title: 'photography',
        url: '/photos',
        cName: 'nav-links'
    },
]